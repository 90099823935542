<template>
    <b-container v-if="selectedClient" fluid>
        <b-row class="flex-center my-2">
            <b-form v-on:submit.prevent="createInsurance">
                <table class="table b-table table-striped table-hover table-bordered text-center">
                    <thead>
                    <tr class="align-middle">
                        <th>
                            Bosna i Hercegovina<br/>
                            FEDERACIJA BiH<br/>
                            Federalno ministarstvo financija/finansija<br/>
                            POREZNA UPRAVA
                        </th>
                        <th colspan="2">Obrazac 2001<br/>
                            <h4>Specifikacija uz isplatu plaća<br/> zaposlenika u radnom odonosu kod pravnih i fizičkih osoba</h4>
                        </th>
                        <th> Za službenu upotrebu</th>
                    </tr>
                    <tr class="align-middle">
                        <th colspan="4">
                            Dio 1 - Podaci o poslodavcu/isplatitelju i plaćama
                        </th>
                    </tr>
                    <tr class="align-middle">
                        <td colspan="2">1) Naziv: {{selectedClient.name}}</td>
                        <td colspan="2">2) JIB/JMB: {{selectedClient.owner_jmbg}}</td>
                    </tr>
                    <tr class="align-middle">
                        <td colspan="2">3) Adresa: {{selectedClient.address}}</td>
                        <td>4) Općina: {{selectedClient.city.township.name}}</td>
                        <td><b-form-group
                                class="w-100"
                                horizontal
                                :label-cols="4"
                                breakpoint="md"
                                label="5) Razdoblje (od/do) (dan/mjesec/godina): "
                                label-for="period">
                            <b-form-input id="date_from" type="date" size="sm" name="date_from" required></b-form-input>
                            <b-form-input id="date_to" type="date" size="sm" name="date_to" required></b-form-input>
                        </b-form-group></td>
                    </tr>
                    <tr class="align-middle">
                        <td colspan="2"> 6) Vrsta djelatnosti: {{selectedClient.address}}</td>
                        <td colspan="2"><b-form-group
                                class="w-100"
                                horizontal
                                :label-cols="4"
                                breakpoint="md"
                                label="7) Broj zaposlenih: "
                                label-for="employed">
                            <b-form-input id="employed" type="number" size="sm" name="employed" required></b-form-input>
                        </b-form-group></td>
                    </tr>
                    <tr class="align-middle">
                        <td>  8) Plaće u novcu:</td>
                        <td>  9) Plaće u stvarima ili uslugama:</td>
                        <td>  10) Ukupne plaće:</td>
                        <td>   <b-form-group
                                stacked
                                class="w-100">
                            <b-form-radio-group
                                    id="activity_type"
                                    name="activity_type">
                                <b-form-radio value="1">11) Nerezident</b-form-radio><br/>
                                <b-form-radio value="2">12) Izuzeci po članku 6. točka 10. Zakona</b-form-radio><br/>
                                <b-form-radio value="3">13) Konsolidacija gospodarskih društava</b-form-radio><br/>
                                <b-form-radio value="3">14) Po osnovu dugovanja sport. kolektiva</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group></td>
                    </tr>
                    <tr class="align-middle">
                        <td colspan="4"><b-form-group
                                class="w-100"
                                label="15) Vrsta isplate (Označiti odgovarajuće polje):">
                            <b-form-radio-group
                                    id="activity_type"
                                    name="activity_type">
                                <b-form-radio value="1">a) doprinosa i poreza</b-form-radio>
                                <b-form-radio value="2">b) samo doprinosa</b-form-radio>
                                <b-form-radio value="3">c) samo poreza</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group></td>
                    </tr>
                    <tr class="align-middle">
                        <th colspan="4">
                            Dio 2 - Podaci o doprinosima iz osnovice (na teret osiguranika)
                        </th>
                    </tr>
                    <tr class="align-middle">
                        <th>Red.br.</th>
                        <th>Opis</th>
                        <th>Stopa</th>
                        <th>Iznos</th>
                    </tr>
                    <tr class="align-middle">
                        <th>a</th>
                        <th>b</th>
                        <th>c</th>
                        <th>d</th>
                    </tr>
                    <tr class="align-middle">
                        <th>16</th>
                        <th>Doprinosi za mirovinsko i invalidsko osiguranje</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>17</th>
                        <th>Doprinosi za zdravstveno osiguranje</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>18</th>
                        <th>Doprinosi za osiguranje od nezaposlenosti</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>19</th>
                        <th>Ukupni doprinosi (16+17+18)</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>

                    <tr class="align-middle">
                        <th colspan="4">
                            Dio 3 - Podaci o doprinosima na osnovicu (na teret poslodavca (isplatitelja)
                        </th>
                    </tr>
                    <tr class="align-middle">
                        <th>Red.br.</th>
                        <th>Opis</th>
                        <th>Stopa</th>
                        <th>Iznos</th>
                    </tr>
                    <tr class="align-middle">
                        <th>a</th>
                        <th>b</th>
                        <th>c</th>
                        <th>d</th>
                    </tr>
                    <tr class="align-middle">
                        <th>20</th>
                        <th>Doprinosi za mirovinsko i invalidsko osiguranje</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>21</th>
                        <th>Doprinosi za zdravstveno osiguranje</th>
                        <th><b-form-input id="zdrav" type="text" size="sm" name="zdrav" required></b-form-input></th>
                        <th><b-form-input id="zdrav_izn" type="text" size="sm" name="zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>22</th>
                        <th>Doprinosi za osiguranje od nezaposlenosti</th>
                        <th><b-form-input id="neup" type="text" size="sm" name="neup" required></b-form-input></th>
                        <th><b-form-input id="neup_izn" type="text" size="sm" name="neup_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>23</th>
                        <th>Dodatni doprinosi za mirovinsko i invalidsko osiguranje</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>24</th>
                        <th>Dodatni doprinosi za zdravstveno osiguranje</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>25</th>
                        <th>UKUPNO DOPRINOSI (20 + 21 + 22 + 23 + 23)</th>
                        <th colspan="2"></th>
                    </tr>

                    <tr class="align-middle">
                        <th colspan="4">
                            Dio 4 - Podaci o obvezama
                        </th>
                    </tr>
                    <tr class="align-middle">
                        <th>Red.br.</th>
                        <th>Opis</th>
                        <th>Stopa</th>
                        <th>Iznos</th>
                    </tr>
                    <tr class="align-middle">
                        <th>a</th>
                        <th>b</th>
                        <th>c</th>
                        <th>d</th>
                    </tr>
                    <tr class="align-middle">
                        <th>26</th>
                        <th>Doprinosi za mirovinsko i invalidsko osiguranje (16 + 20 + 23)</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>27</th>
                        <th>Doprinosi za zdravstveno osiguranje (17 + 21 + 24)</th>
                        <th><b-form-input id="zdrav" type="text" size="sm" name="zdrav" required></b-form-input></th>
                        <th><b-form-input id="zdrav_izn" type="text" size="sm" name="zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>28</th>
                        <th>Doprinosi za osiguranje od nezaposlenosti (18 + 22)</th>
                        <th><b-form-input id="neup" type="text" size="sm" name="neup" required></b-form-input></th>
                        <th><b-form-input id="neup_izn" type="text" size="sm" name="neup_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>29</th>
                        <th>Porez na dohodak</th>
                        <th><b-form-input id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input></th>
                        <th><b-form-input id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input></th>
                    </tr>
                    <tr class="align-middle">
                        <th>30</th>
                        <th>UKUPNO OBVEZE (26 + 27 + 28 + 29</th>
                        <th colspan="2"></th>
                        <th></th>
                    </tr>

                    <tr class="align-middle">
                        <th colspan="4">
                            Dio 5 - Izjava poduzetnika
                        </th>
                    </tr>
                    <tr class="align-middle">
                        <td colspan="4">Upoznat sam sa svim sankcijama propisanim Zakonom i drugim propisima, izjavljujem da su podaci navedeni u ovoj
                            specifikaciji uključujući sve priloge potpuni, točni i jasni.</td>
                    </tr>
                    <tr class="align-middle">
                        <th colspan="2">Potpis poreznog obveznika:</th>
                        <th colspan="2">Datum: </th>
                    </tr>
                    </thead>
                </table>
            </b-form>
        </b-row>

        <b-row class="flex-center no-border">
            <b-btn type="submit" variant="primary" class="my-4 w-50">Potvrdi</b-btn>
        </b-row>

    </b-container>
</template>

<script>
    export default {
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            createInsurance: function (e) {
                const formData = new FormData(e.target);
                formData.append('client_id', this.selectedClient.id);
                axiOsta.post(api.insurances, formData).then( response => {
                        if (response.status === 201) {
                            this.$router.push('/insurances/' + response.data.id);
                        }
                    }
                )
            }
        }
    }
</script>
